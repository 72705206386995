import moment from "moment"
import React from "react"
import { message, Modal, Table } from "antd"

export const getInputRecord = (arr, key, defId, staffNo, isSame) => {
  let newArr = arr.find(
    item => item[key] === defId && (item.staff.staffNo === staffNo) === isSame
  )
  return typeof newArr !== "undefined" ? newArr : null
}

export const getOtherRecord = (arr, key, defId, staffNo, selfNo) => {
  let newArr = arr.filter(
    item =>
      item[key] === defId &&
      item.staff.staffNo !== staffNo &&
      item.staff.staffNo !== selfNo
  )
  return typeof newArr !== "undefined" ? newArr : null
}

export const translate = (arr, str) => {
  if (Array.isArray(arr) && arr.length > 0 && !!str) {
    const matchStr = str.includes("zh") ? "zh-HK" : "en-US"
    const item = arr.find(item => item.cultureInfoName === matchStr)
    return item?.message
  }

  return " - "
}

export const tranformFile = (arr, url) => {
  if (!!arr) {
    return arr.map(item => ({
      uid: item.id,
      name: item.fileName,
      url: process.env.REACT_APP_URL + "/api/" + url + "/Attachment/" + item.id,
    }))
  }
  return []
}

export const transformTime = date => {
  return date ? moment(date).format("DD MMM YYYY HH:mm:ss") : null
}

export const transformDate = date => {
  return date ? moment(date).format("DD MMM YYYY") : null
}

export const getNowDate = date => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss")
}

export const PrinciplesDescription = ({ description }) => (
  <div dangerouslySetInnerHTML={{ __html: description }} />
)

export const getPrincipleName = (arr, key, recordId, lang) => {
  let principle = arr.find(item => item[key] === recordId)
  return translate(principle.name, lang)
}

export const getPrincipleRecord = (arr, key, defId) => {
  return arr.filter(item => item[key] === defId)
}

export const getPrincipleType = (arr, key, recordId) => {
  return arr.find(item => item[key] === recordId).commentType
}

export const getFiles = history => {
  return history.attachment
    ? [
        {
          uid: history.id,
          name: history.attachment,
          url:
            process.env.REACT_APP_URL +
            "/api/AppraisalComment/" +
            history.id +
            "/Attachment",
        },
      ]
    : []
}

export const errorHandle = () => {
  if (typeof window !== "undefined") {
    alert(
      "There is a problem with network connection or the form has been updated.\nPlease click OK to refresh your page\n\n網絡連線出現問題或表格已被更新,\n請按確定以刷新你的頁面"
    )
    window.location.reload()
  }
  return null
}

export const calAvgScore = arr => {
  console.log(arr)
  let newArr = arr.map(item => item.rating)
  console.log(newArr)
  let sum = newArr.reduce((a, b) => a + b)
  return (sum / arr.length).toFixed(1)
}

export const getAvgRecord = (firstRecord, arr, stepList) => {
  let resultArr = []
  let lastRating = firstRecord.rating
  stepList.forEach(step => {
    let avg = arr.filter(item => item.step === step.step)
    if (step.approver.length === 1) {
      if (avg.length > 0) {
        resultArr.push({ avgRating: avg[0].rating, step: step.step })
        lastRating = avg[0].rating
      } else {
        resultArr.push({
          avgRating: lastRating,
          step: step.step,
        })
      }
    } else if (step.approver.length > 1) {
      //check all user amend
      if (avg.length > 0) {
        let newScore = calAvgScore(avg)
        if (avg.length === step.approver.length) {
          resultArr.push({ avgRating: newScore, step: step.step })
          lastRating = newScore
        } else {
          let diff = step.approver.length - avg.length

          newScore =
            (newScore * avg.length + lastRating * diff) / step.approver.length
          newScore = newScore.toFixed(1)
          resultArr.push({ avgRating: newScore, step: step.step })
          lastRating = newScore
        }
      } else {
        resultArr.push({
          avgRating: lastRating,
          step: step.step,
        })
      }
    }
  })
  return resultArr
}

export const showSuccess = msg => {
  message.success(msg)
}

export const showError = msgArr => {
  try {
    for (var key in msgArr) {
      if (!msgArr.hasOwnProperty(key)) continue
      var objArr = msgArr[key]
      objArr.map(msg => message.error(msg))
    }
  } catch (err) {
    message.error("System Error Occur")
  }
}

export const DownloadData = async apiClient => {
  let client = new apiClient()
  client
    .download()
    .then(result => {
      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", result.fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
    .catch(err => {
      console.log(err)
      message.error("Download Error !")
    })
}

export const DownloadConfigData = async (apiClient, id, isSiteKPI, isSite) => {
  let client = new apiClient()
  let result
  try {
    if (isSiteKPI) {
      result = await client.download(id, isSite)
    } else {
      result = await client.download(id)
    }
    console.log(result)
    const url = window.URL.createObjectURL(new Blob([result.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", result.fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
  } catch (err) {
    console.log(err)
    message.error("Download Error !")
  }
}

export const editorConfig = {
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  toolbarAdaptive: false,
  buttons:
    "|,bold,strikethrough,underline,italic,eraser,|,superscript,subscript,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,paragraph,|,link,|,align,\n,|,hr",

  height: 500,
}

export const errorMessage = (action, err) => {
  let modalArr = []
  console.log(err)
  if (!Array.isArray(err)) {
    let errmsg = JSON.parse(err.response)

    let msgArr = errmsg.errors

    for (var key in msgArr) {
      if (!msgArr.hasOwnProperty(key)) continue
      var objArr = msgArr[key]
      modalArr.push({ key: key, msg: objArr })
    }
  } else {
    err.forEach(item => {
      modalArr.push({ key: " - ", msg: [item] })
    })
  }
  const columns = [
    {
      title: "Title",
      dataIndex: "key",
    },
    {
      title: "Descriptions",
      dataIndex: "msg",
      render: text => text.map(item => item + ", "),
    },
  ]

  Modal.error({
    centered: true,
    title: action + " Error",
    okText: "OK",
    content: (
      <Table
        dataSource={modalArr}
        columns={columns}
        pagination={{ position: ["bottomCenter"] }}
        size="small"
      />
    ),
    width: 900,
  })
}

export const formateScore = score => {
  return score && score === 0 ? score : null
}

export const getNewIndex = (prevState, nextState) => {
  let newIndex = prevState.pageIndex
  if (nextState.pageIndex || nextState.pageIndex === 0) {
    newIndex = nextState.pageIndex + 1
  }
  return newIndex
}

export const normFile = ({ fileList }) => {
  if (fileList.length) {
    const file = fileList[0]
    if (file.size / 1024 / 1024 > 10) {
      message.error("檔案不能超過10 Mbs")
      return null
    }
    return { data: file.originFileObj, fileName: file.name }
  }
  return null
}

export const downloadFile = result => {
  console.log(result)
  const url = window.URL.createObjectURL(new Blob([result.content]))
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", result.fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
}

export const downloadData = result => {
  console.log(result)
  const url = window.URL.createObjectURL(new Blob([result.data]))
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", result.fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
}

export const getScoreByStep = (arr, key, defId, step) => {
  const newArr = arr.filter(item => item[key] === defId)
  if (!newArr.length) return null
  newArr.sort((a, b) => b.step - a.step)
  const stepIndex = newArr.findIndex(item => item.step === step)
  if (stepIndex !== -1) return newArr[stepIndex]
  return newArr[0]
}

export const groupBy = (array, key) => {
  const initialValue = {}
  const sortedArray = array.sort((a, b) => a.order - b.order)
  return sortedArray.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    }
  }, initialValue)
}

export const countPages = (response = null) => {
  if (!response) return null
  const scannedResponse = JSON.parse(response)
  return scannedResponse["0"]?.Pages.map(page => page + 1).join(", ") ?? null
}
